html,
body {
  height: 100%;
}

* {
  font-family: 'Roboto', sans-serif;
}

body,
p,
a,
h1,
h2,
h3,
h4,
h5,
a,
i {
  font-family: 'Roboto', sans-serif;
}

p {
  font-size: 14px;
}

@media(max-width: 480px) {
  .bg-cover {
    background-size: cover !important;
  }

  .d-xs-none {
    display: none !important;
  }
}

.dropdown-item.active,
.dropdown-item:active {
  background-color: #ccc;
}

.bg-gray {
  background-color: #eee;
}

.badge {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 2px 16px;
  gap: 4px;
  background: #cae3f9;
  border-radius: 30px;
  width: 112px;
  height: 23px;
  color: black !important;
}